import { getService } from './api'
import notify from "devextreme/ui/notify";
const baseUrl = process.env.REACT_APP_PARTICIPANTSUITE_BACKEND_URL;
export async function refreshToken(token) {

    const url = `${baseUrl}/bo/refreshToken`;
    // Call to the api to refresh the token
    const res  = await getService(token, url);

    // Return token as a string instead of an object
    if (res?.isOk) {
        return res.data.toString()
    }
    else {
        notify(res.message, "error", 2000);
    }
}
