import { getService } from './api'
import notify from "devextreme/ui/notify";
const baseUrl = process.env.REACT_APP_PARTICIPANTSUITE_BACKEND_URL;

export async function fetchPrograms() {
  const url = `${baseUrl}/programs`;
  const res = await getService(null, url);
  if (res?.isOk) {
    return res
  }
  else {
    notify(res.message, "error", 2000);
  }
}
