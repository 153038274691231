import React, { createContext, useContext, useEffect, useState } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { fetchPrograms } from './api/programs'

const ProgramContext = createContext({})
function Programs({program, children}) {
  return <ProgramContext.Provider value={program}>
    { /*<div style={{position: 'absolute',top: '5px', left: '5px', zIndex:100}}>{ program.name }</div>*/ }
    { children }
  </ProgramContext.Provider>
}
export const useProgram = () => useContext(ProgramContext)

export function MultiPrograms({children}){
  const [ programs, setPrograms ] = useState([])

  const fetchAllPrograms = React.useCallback(async () => {
    const fetchedPrograms = await fetchPrograms();
    if (fetchedPrograms?.isOk) {
      setPrograms(fetchedPrograms.data);
    }
  }, []);

  useEffect(() => {
    fetchAllPrograms()
  }, [fetchAllPrograms])

  return <Switch>
    { programs.map(program =>
      <Route path={`/${program.name.toLowerCase()}`} key={program.id}>
        <Programs program={program}>{ children }</Programs>
      </Route>
    )}
    { programs?.length > 0 && <Redirect to={`/${programs[0].name.toLowerCase()}`} /> }
  </Switch>
}


